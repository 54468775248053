if (!Object.removeKey) {
  Object.removeKey = (object, key) =>
    Object.keys(object).reduce((obj, k) => {
      if (k === key.toString()) {
        return obj;
      }

      return {
        ...obj,
        [k]: object[k],
      };
    }, {});
}
