import flatpickr from 'flatpickr';
import { Czech } from 'flatpickr/dist/l10n/cs';
import { German } from 'flatpickr/dist/l10n/de';

function localizeFlatpickr(locale) {
  const l = locale.toLowerCase();

  if (l === 'cs') {
    Czech.rangeSeparator = ' až ';

    flatpickr.localize(Czech);
  } else if (l === 'de') {
    flatpickr.localize(German);
  }
}

export default localizeFlatpickr;
