import Rails from '@rails/ujs';

function startRails() {
  Rails.href = (element) => element.href || element.getAttribute('data-url');

  if (!window.railsUjsStarted) {
    Rails.start();
  }

  window.railsUjsStarted = true;
}

export default startRails;
